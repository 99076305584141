<template>
  <div v-loading="loadinga">
    <el-card>
      {{ username }}<el-divider direction="vertical"></el-divider>权限组：{{ usergroup }}<el-divider
        direction="vertical"></el-divider> 剩余积分：{{ integral }}
    </el-card>
    <el-card>
      <el-row :gutter="20">
        <el-col :xs="24" :md="12" :lg="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <i class="fa fa-wheelchair-alt" aria-hidden="true"></i>
              <span>免费用户</span>
            </div>
            <el-descriptions :column="1">
              <el-descriptions-item label="节点权限">普通节点</el-descriptions-item>
              <el-descriptions-item label="国内节点速率限制">8m</el-descriptions-item>
              <el-descriptions-item label="国外节点速率限制">32m</el-descriptions-item>
              <el-descriptions-item label="隧道数量">4条</el-descriptions-item>
              <el-descriptions-item label="流量限制">不限制</el-descriptions-item>
              <el-descriptions-item label="官方技术支持">不支持</el-descriptions-item>
            </el-descriptions>
            <el-divider></el-divider>
            <span>0积分</span>
            <el-button type="danger" round plain disabled style="float: right; padding: 5px 20px">无法购买</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :md="12" :lg="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <i class="fa fa-car" aria-hidden="true"></i>
              <span>普通会员</span>
            </div>
            <el-descriptions :column="1">
              <el-descriptions-item label="节点权限">普通节点/会员节点</el-descriptions-item>
              <el-descriptions-item label="国内节点速率限制">16m</el-descriptions-item>
              <el-descriptions-item label="国外节点速率限制">64m</el-descriptions-item>
              <el-descriptions-item label="隧道数量">8条</el-descriptions-item>
              <el-descriptions-item label="流量限制">不限制</el-descriptions-item>
              <el-descriptions-item label="官方技术支持">不支持</el-descriptions-item>
            </el-descriptions>
            <span style="color: #F56C6C;">限时优惠，永久会员仅需80元<br/>添加客服QQ：242247494购买</span>
            <el-divider></el-divider>
            <span>8000积分/月</span>
            <el-button type="primary" @click="gm = true" round plain
              style="float: right; padding: 5px 20px">查看详情</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :md="12" :lg="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <i class="fa fa-plane" aria-hidden="true"></i>
              <span>高级会员</span>
            </div>
            <el-descriptions :column="1">
              <el-descriptions-item label="节点权限">普通节点/会员节点</el-descriptions-item>
              <el-descriptions-item label="国内节点速率限制">24m</el-descriptions-item>
              <el-descriptions-item label="国外节点速率限制">96m</el-descriptions-item>
              <el-descriptions-item label="隧道数量">12条</el-descriptions-item>
              <el-descriptions-item label="流量限制">不限制</el-descriptions-item>
              <el-descriptions-item label="官方技术支持">每月一次</el-descriptions-item>
            </el-descriptions>
            <span style="color: #F56C6C;">限时优惠，永久会员仅需120元<br/>添加客服QQ：242247494购买</span>
            <el-divider></el-divider>
            <span>12000积分/月</span>
            <el-button type="primary" @click="gm = true" round plain
              style="float: right; padding: 5px 20px">查看详情</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :md="12" :lg="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <i class="fa fa-rocket" aria-hidden="true"></i>
              <span>超级会员</span>
            </div>
            <el-descriptions :column="1">
              <el-descriptions-item label="节点权限">普通节点/会员节点</el-descriptions-item>
              <el-descriptions-item label="国内节点速率限制">32m</el-descriptions-item>
              <el-descriptions-item label="国外节点速率限制">128m</el-descriptions-item>
              <el-descriptions-item label="隧道数量">16条</el-descriptions-item>
              <el-descriptions-item label="流量限制">不限制</el-descriptions-item>
              <el-descriptions-item label="官方技术支持">每月两次</el-descriptions-item>
            </el-descriptions>
            <span style="color: #F56C6C;">限时优惠，永久会员仅需160元，并提供一次额外技术支持<br/>添加客服QQ：242247494购买</span>
            <el-divider></el-divider>
            <span>18000积分/月</span>
            <el-button type="primary" @click="gm = true" round plain
              style="float: right; padding: 5px 20px">查看详情</el-button>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <el-drawer title="会员购买" size="40%" :visible.sync="gm">
      <el-alert title="注意！" :closable="false" type="warning" description="确认购买后，积分概不退换。多次购买同一套餐则增加对应会员时长。" show-icon>
      </el-alert>
      <el-divider></el-divider>
      <el-tabs type="border-card">
        <el-tab-pane label="购买套餐">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="购买套餐" prop="gmtc">
              <el-radio-group v-model="ruleForm.gmtc">
                <el-radio label="普通会员"></el-radio>
                <el-radio label="高级会员"></el-radio>
                <el-radio label="超级会员"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="功能选择">
              <el-checkbox-group v-model="ruleForm.gnxz" disabled>
                <el-checkbox label="到期前三天邮件提示" name="type"></el-checkbox>
                <el-checkbox label="节点故障邮件提示" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="购买时长" prop="gmsc">
              <el-radio-group v-model="ruleForm.gmsc">
                <el-radio label="一月"></el-radio>
                <el-radio label="一季"></el-radio>
                <el-radio label="半年"></el-radio>
                <el-radio label="一年"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="自动续费">
              <el-switch v-model="ruleForm.zdxf" disabled></el-switch>
            </el-form-item>
            <el-form-item label="积分详情">
              本次消耗积分：{{ integralConsumption[ruleForm.gmtc] * durationConsumption[ruleForm.gmsc] }}<el-divider
                direction="vertical"></el-divider>
              购买后剩余总积分：{{ integral - integralConsumption[ruleForm.gmtc] * durationConsumption[ruleForm.gmsc] }}<el-divider
                direction="vertical"></el-divider>
              购买前总积分：{{ integral }}
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')" v-loading="loadinggm">立即购买</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="升级套餐">
          <el-form :model="sjtcForm" :rules="rules" ref="sjtcForm" label-width="100px">
            <el-form-item label="升级套餐" prop="sjtc">
              <el-radio-group v-model="sjtcForm.sjtc">
                <el-radio label="高级会员"></el-radio>
                <el-radio label="超级会员"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="升级详情">
              您当前套餐：{{ usergroup }}
              <el-divider direction="vertical"></el-divider>
              到期时间：{{ term }}
              <el-divider direction="vertical"></el-divider>
              剩余天数：{{ remainingDays }} 天
            </el-form-item>
            <el-form-item label="积分详情">
              本次消耗积分：{{ upgradePoints }}积分
              <el-divider direction="vertical"></el-divider>
              购买后剩余总积分：{{ remainingPoints }}积分
              <el-divider direction="vertical"></el-divider>
              购买前总积分：{{ integral }}积分
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="ssjtcForm('sjtcForm')" v-loading="loadingsj">立即升级</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="自定义套餐">
          如果当前的所有套餐都不能满足您的需求
          <br />
          可联系QQ：242247494
          <br />
          咨询定制会员
          <br />
          定制会员需要在永久超级会员的基础上升级
          <br />
          10元/m 10元/隧道
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
  </div>
</template>
  
<script>
import { setToken, getToken, removeToken } from '@/utils/setToken.js';
import axios from 'axios';

export default {
  data() {
    return {
      direction: '',
      loadinga: false,
      loadinggm: false,
      loadingsj: false,
      gm: false,
      ruleForm: {
        gmtc: '普通会员',
        gnxz: [],
        gmsc: '一月',
        zdxf: false,
      },
      sjtcForm: {
        sjtc: '高级会员',
      },
      rules: {
        gmtc: [
          { required: true, message: '请选择购买套餐', trigger: 'change' }
        ],
        gmsc: [
          { required: true, message: '请选择购买时长', trigger: 'change' }
        ],
        sjtc: [
          { required: true, message: '请选择升级的套餐', trigger: 'change' }
        ],
      },
      integralConsumption: {
        '普通会员': 8000,
        '高级会员': 12000,
        '超级会员': 18000,
      },
      durationConsumption: {
        '一月': 1,
        '一季': 3,
        '半年': 6,
        '一年': 12,
      },
    }
  },

  computed: {
    remainingDays() {
      if (this.term) {
        const today = new Date();
        const expiryDate = new Date(this.term);
        const monthsDiff = (expiryDate.getFullYear() - today.getFullYear()) * 12 + (expiryDate.getMonth() - today.getMonth());
        const daysRemaining = Math.ceil(monthsDiff * 30);
        return daysRemaining > 0 ? daysRemaining : 0;
      }
      return 0;
    },

    upgradePoints() {
      if (this.usergroup === '普通会员') {
        if (this.sjtcForm.sjtc === '高级会员') {
          return 133 * this.remainingDays;
        } else if (this.sjtcForm.sjtc === '超级会员') {
          return 333 * this.remainingDays;
        }
      } else if (this.usergroup === '高级会员') {
        if (this.sjtcForm.sjtc === '超级会员') {
          return 200 * this.remainingDays;
        }
      }
      return 0;
    },

    remainingPoints() {
      return this.integral - this.upgradePoints;
    }
  },

  created() {
    this.username = getToken('username');
    this.usergroup = getToken('usergroup');
    this.integral = getToken('integral');
    this.term = getToken('term');
    const usertoken = getToken('token');

    if (usertoken) {
      this.setData();
    } else {
      removeToken('token');
      this.$router.push('/login');
      this.$message({
        message: "无效的token，请重新登录",
        type: "error",
      });
    }
  },
  methods: {

    submitForm() {
      this.loadinggm = true;
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          const params = {
            package: this.ruleForm.gmtc,
            term: this.ruleForm.gmsc,
            userid: getToken('userid'),
            usertoken: getToken('token'),
          };
          axios.get('https://cf-v1.uapis.cn/api/jfsc.php', { params })
            .then(response => {
              if (response.data.status === 'success') {
                this.$alert('您本次消耗积分：' + response.data.points + '，' + response.data.package + '剩余时长：' + response.data.daoqi, '购买成功！', {
                  confirmButtonText: '确定',
                  type: 'success',
                }).then(() => {
                  this.$message({
                    type: 'success',
                    message: response.data.message,
                  });
                });
                this.setData();
              } else {
                this.$message({
                  message: response.data.message,
                  type: 'error'
                });
              }
            })
            .catch(error => {
              console.error('请求API时发生错误:', error);
              this.$message({
                message: '购买失败，请稍后重试',
                type: 'error'
              });
            })
            .finally(() => {
              this.loadinggm = false;
            });
        }
      });
    },

    ssjtcForm() {
      this.loadingsj = true;
      if (this.upgradePoints > this.integral) {
        this.$message({
          message: "积分不足，无法升级。",
          type: "error"
        });
        return;
      }
      this.$refs['sjtcForm'].validate(valid => {
        if (valid) {
          const params = {
            package: this.sjtcForm.sjtc,
            usertoken: getToken('token'),
          };
          axios.get('https://cf-v1.uapis.cn/api/tcsj.php', { params })
            .then(response => {
              if (response.data.success) {
                this.$alert('您本次消耗积分：' + response.data.xhjf, '升级成功！', {
                  confirmButtonText: '确定',
                  type: 'success',
                }).then(() => {
                  this.$message({
                    type: 'success',
                    message: response.data.message,
                  });
                });
                this.setData();
              } else {
                this.$message({
                  message: response.data.message,
                  type: 'error'
                });
              }
            })
            .catch(error => {
              console.error('请求API时发生错误:', error);
              this.$message({
                message: '升级失败，请稍后重试',
                type: 'error'
              });
            })
            .finally(() => {
              this.loadingsj = false;
            });
        }
      });
    },

    setData(params) {
      this.loadinga = true;
      const usertoken = getToken('token');
      axios
        .get('https://cf-v1.uapis.cn/api/userinfo.php', {
          params: { usertoken, ...params },
        })
        .then(response => {
          if (response.data.error) {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
              message: "无效的token，请重新登录",
              type: "error",
            });
          } else {
            const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

            setToken('userid', userid);
            setToken('username', username);
            setToken('email', email);
            setToken('qq', qq);
            setToken('usergroup', usergroup);
            setToken('bandwidth', bandwidth);
            setToken('tunnel', tunnel);
            setToken('realname', realname);
            setToken('integral', integral);
            setToken('userimg', userimg);
            setToken('term', term);
            setToken('tunnelstate', tunnelstate);
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        })
        .finally(() => {
          this.loadinga = false;
        })
    }
  }
}
</script>
  
<style lang="less" scoped>
.row {
  min-height: 400px;

  .card-left-top {
    min-height: 200px;
  }

  .card-left-bottom {
    min-height: 200px;
    margin-top: 16px;
  }

  .card-right {
    min-height: 416px;
  }
}
.el-col {
  margin-top: 16px;
}
</style>